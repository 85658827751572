import React, { Component } from 'react';
import { navigate } from "@reach/router"

import CartContext from '../Context/CartContext'

const isValid = (cart) => {
  if (cart && cart.requiresAddress()) {
    return cart.email && cart.firstname && cart.lastname && cart.invoiceStreet && cart.invoiceStreetnr && cart.invoiceCity && cart.invoicePostalcode
  } else if (cart && !cart.requiresAddress()) {
    return cart.email && cart.firstname && cart.lastname
  }
  return false
}

export default class CheckoutIdentification extends Component {
  cart = null

  state = {
    email: { value: null, changed: false },
    firstname: { value: null, changed: false },
    lastname: { value: null, changed: false },
    invoiceStreet: { value: null, changed: false },
    invoiceStreetnr: { value: null, changed: false },
    invoicePostalcode: { value: null, changed: false },
    invoiceCity: { value: null, changed: false },
    invoiceCountry: 'BE',
    changed: false,
    disabled: false,
    error: false
  }

  set = (e) => {
    e.preventDefault()
    this.setState({
      changed: true,
      [e.target.name]: {
        value: e.target.value,
        changed: true
      }
    })
  }

  save = (e, updateCmd, setCmd) => {
    e.preventDefault()

    if (this.state.email.changed) setCmd('email', this.state.email.value)
    if (this.state.firstname.changed) setCmd('firstname', this.state.firstname.value)
    if (this.state.lastname.changed) setCmd('lastname', this.state.lastname.value)
    if (this.state.invoiceStreet.changed) setCmd('invoiceStreet', this.state.invoiceStreet.value)
    if (this.state.invoiceStreetnr.changed) setCmd('invoiceStreetnr', this.state.invoiceStreetnr.value)
    if (this.state.invoicePostalcode.changed) setCmd('invoicePostalcode', this.state.invoicePostalcode.value)
    if (this.state.invoiceCity.changed) setCmd('invoiceCity', this.state.invoiceCity.value)
    if (this.state.changed) setCmd('invoiceCountry', this.state.invoiceCountry)

    if (isValid(this.cart)) {
      if (this.state.changed) {
        updateCmd()
          .then((cart) => {
            navigate(`/checkout/delivery`)
          })
      } else {
        navigate(`/checkout/delivery`)
      }
    } else {
      this.setState({ error: true })
    }
  }

  render() {
    const { error } = this.state
    const disabled = false

    return (
      <CartContext.Consumer>
        {({ cart, set, update }) => {
          if (cart) {
            this.cart = cart

            return (
              <form className="form-addToCart" onSubmit={e => this.save(e, update, set)}>
                <div className="card mb-2">
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor="inputEmail">E-mail</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputEmail" name="email" defaultValue={cart.email} placeholder="Uw e-mail adres" onChange={e => this.set(e)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor="inputFirstName">Voornaam</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputFirstName" name="firstname" defaultValue={cart.firstname} placeholder="" onChange={e => this.set(e)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label" htmlFor="inputLastName">Naam</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputLastName" name="lastname" defaultValue={cart.lastname} placeholder="" onChange={e => this.set(e)} />
                      </div>
                    </div>
                    {cart.requiresAddress() ? (
                      <>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="invoiceStreet">Straat</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="invoiceStreet" name="invoiceStreet" defaultValue={cart.invoiceStreet} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="invoiceStreet">Huisnummer</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="invoiceStreetnr" name="invoiceStreetnr" defaultValue={cart.invoiceStreetnr} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="invoicePostalcode">Postcode</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="invoicePostalcode" name="invoicePostalcode" defaultValue={cart.invoicePostalcode} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="invoiceCity">Stad</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="invoiceCity" name="invoiceCity" defaultValue={cart.invoiceCity} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                {error ? (
                  <div className="alert alert-danger">
                    <p className="p-0 m-0">Gelieve alle velden correct in te vullen</p>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-4 offset-md-8">
                    <button type="submit" className="btn btn-primary btn-block" disabled={disabled}>
                      <i className="fa fa-chevron-right mr-3" aria-hidden="true"></i>
                      <span className="">Naar de volgende stap</span>
                    </button>
                  </div>
                </div>
              </form>
            )
          }

          return null
        }}
      </CartContext.Consumer>
    );
  }
}
