import React from 'react'

import Layout from '../../components/layout'
import CheckoutIdentification from '../../components/CheckoutIdentification'

export default () => (
  <Layout>
    <h1 className="h2">Van wie komt deze cadeaubon?</h1>

    <div className="row">
      <div className="col-12">

        <CheckoutIdentification />

      </div>
    </div>
  </Layout>
)
